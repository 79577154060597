<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">当地岗位服务外包费明细</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          当地岗位服务外包费明细</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">缩写名称:</div>
          <el-input
            v-model="abbreviationName"
            placeholder="缩写名称"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目名称:</div>
          <el-select
            v-model="projectID"
            placeholder="项目名称"
            filterable
            clearable
            @change="changeProjectID"
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">分项目名称:</div>
          <el-select
            v-model="subProjectID"
            placeholder="分项目名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in subProjectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">单位名称:</div>
          <el-select
            v-model="companyID"
            placeholder="岗位名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">部门名称:</div>
          <el-select
            v-model="departmentID"
            placeholder="岗位名称"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.ID"
              :label="item.DepartmentCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">甲方:</div>
          <el-select
            v-model="firstParty"
            placeholder="甲方"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in GovernmentList"
              :key="item.ID"
              :label="item.NameinChinese"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>

        <div class="screen-input-box">
          <div class="title-box">挂账日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button
          class="btn-pre"
          @click="getManpowerSupplierMonthlyCostAccountings(1)"
        >
          <i class="fa fa-search"></i>查询
        </button>
        <button
          class="btn-pre"
          @click="exportManpowerSupplierMonthlyCostAccountings"
        >
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="MonthlyCost" label="挂账月份" width="80" />
          <el-table-column prop="FirstPartyName" label="甲方" width="120" />
          <el-table-column
            prop="ProjectName"
            label="项目名称"
            width="100"
          /><el-table-column
            prop="SubProjectName"
            label="分项目名称"
            width="100"
          />
          <el-table-column
            prop="AbbrNameofSupplier"
            label="岗位外包单位名称缩写"
            width="150"
          />
          <el-table-column prop="EmployeeName" label="员工姓名" width="80">
          </el-table-column>
          <el-table-column prop="IQAMAID" label="IQAMA" width="80" />
          <el-table-column prop="Rate" label="单价" width="80" />
          <el-table-column prop="WorkingHours" label="工时数" width="70" />
          <el-table-column
            prop="AmountWithoutVAT"
            label="发票不含VAT额"
            width="120"
          />
          <el-table-column prop="JobNatureName" label="岗位性质" width="100" />
          <el-table-column
            prop="CategoryAndDisciplineName"
            label="岗位系统/专业"
            width="120"
          />
          <el-table-column
            prop="JobProfessionName"
            label="岗位名称"
            width="100"
          />
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="query(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getManpowerSupplierMonthlyCostAccountings(1)"
          @current-change="getManpowerSupplierMonthlyCostAccountings"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="查看当地岗位服务外包费明细"
      :visible.sync="dialogVisible"
      width="1200px"
      :before-close="handleClose"
      top="5vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">挂账月份:</div>
            <el-input
              v-model="parameter.MonthlyCost"
              :disabled="true"
              placeholder="挂账月份"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">甲方:</div>
            <el-input
              v-model="parameter.FirstPartyName"
              :disabled="true"
              placeholder="甲方"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">项目名称:</div>
            <el-input
              v-model="parameter.ProjectName"
              :disabled="true"
              placeholder="项目名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">分项目名称:</div>
            <el-input
              v-model="parameter.SubProjectName"
              :disabled="true"
              placeholder="分项目名称"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">用工单位:</div>
            <el-input
              v-model="parameter.WorkingUnit"
              :disabled="true"
              placeholder="用工单位"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">用工部门:</div>
            <el-input
              v-model="parameter.WorkingDept"
              :disabled="true"
              placeholder="用工部门"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">考勤月份:</div>
            <el-input
              v-model="parameter.MonthofTimesheet"
              :disabled="true"
              placeholder="考勤月份"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">岗位外包单位名称缩写:</div>
            <el-input
              v-model="parameter.AbbrNameofSupplier"
              :disabled="true"
              placeholder="岗位外包单位名称缩写"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">岗位:</div>
            <el-input
              v-model="parameter.CategoryName"
              :disabled="true"
              placeholder="岗位"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">员工姓名:</div>
            <el-input
              v-model="parameter.EmployeeName"
              :disabled="true"
              placeholder="员工姓名"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">IQAMA:</div>
            <el-input
              v-model="parameter.IQAMAID"
              :disabled="true"
              placeholder="IQAMA"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">单价:</div>
            <el-input
              v-model="parameter.Rate"
              :disabled="true"
              placeholder="单价"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">工时数:</div>
            <el-input
              v-model="parameter.WorkingHours"
              :disabled="true"
              placeholder="工时数"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">工作天数:</div>
            <el-input
              v-model="parameter.WorkingDays"
              :disabled="true"
              placeholder="工作天数"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">日均工时数:</div>
            <el-input
              v-model="parameter.DailyWorkingHours"
              :disabled="true"
              placeholder="日均工时数"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">发票不含VAT额:</div>
            <el-input
              v-model="parameter.AmountWithoutVAT"
              :disabled="true"
              placeholder="发票不含VAT额"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">增值税额:</div>
            <el-input
              v-model="parameter.VATAmount"
              :disabled="true"
              placeholder="增值税额"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">发票含VAT额:</div>
            <el-input
              v-model="parameter.AmountWithVAT"
              :disabled="true"
              placeholder="发票含VAT额"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">使用人数:</div>
            <el-input
              v-model="parameter.Quantity"
              :disabled="true"
              placeholder="使用人数"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">岗位性质:</div>
            <el-input
              v-model="parameter.JobNatureName"
              :disabled="true"
              placeholder="岗位性质"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">岗位系统/专业:</div>
            <el-input
              v-model="parameter.CategoryAndDisciplineName"
              :disabled="true"
              placeholder="岗位系统/专业"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">岗位名称:</div>
            <el-input
              v-model="parameter.JobProfessionName"
              :disabled="true"
              placeholder="岗位名称"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">资质:</div>
            <el-input
              v-model="parameter.QulificationName"
              :disabled="true"
              placeholder="资质"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">备注:</div>
            <el-input
              v-model="parameter.Remark"
              :disabled="true"
              placeholder="备注"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose" class="pop-close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getManpowerSupplierMonthlyCostAccountings,
  exportManpowerSupplierMonthlyCostAccountings,
  getGovernmentSystemDicAll,
} from "@/api/table";
import {
  getProjectAll,
  getCompanyAll,
  getProjectsChildren,
  getDepartmentAll,
} from "@/api/user";
import Government from "@/components/tables/government.vue";
export default {
  filters: {
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      options: [
        {
          value: 1,
          label: "公司",
        },
        {
          value: 2,
          label: "政府系统",
        },
      ],
      dialogVisible: false,
      parameter: {},
      loading: true,
      projectList: [],
      GovernmentList: [],
      positionCategoryList: [],
      subProjectList: [],
      companyList: [],
      departmentList: [],
      abbreviationName: null,
      projectID: null,
      subProjectID: null,
      firstParty: null,
      companyID: null,
      departmentID: null,
      daterange1: null,
    };
  },
  components: { Government },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getManpowerSupplierMonthlyCostAccountings(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        abbreviationName: this.abbreviationName,
        projectID: this.projectID,
        subProjectID: this.subProjectID,
        firstParty: this.firstParty,
        companyID: this.companyID,
        departmentID: this.departmentID,
        startDate: this.daterange1 ? this.daterange1[0] : "",
        endDate: this.daterange1 ? this.daterange1[1] : "",
      };
      getManpowerSupplierMonthlyCostAccountings(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //导出
    exportManpowerSupplierMonthlyCostAccountings() {
      const data = {
        abbreviationName: this.abbreviationName,
        projectID: this.projectID,
        subProjectID: this.subProjectID,
        firstParty: this.firstParty,
        companyID: this.companyID,
        departmentID: this.departmentID,
        startDate: this.daterange1 ? this.daterange1[0] : "",
        endDate: this.daterange1 ? this.daterange1[1] : "",
      };
      exportManpowerSupplierMonthlyCostAccountings(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    changeProjectID() {
      this.subProjectID = null;
      this.subProjectList = [];
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      if (!this.projectID) {
        return;
      }
      getProjectsChildren({ id: this.projectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    //查询
    query(data) {
      this.parameter = data;
      this.dialogVisible = true;
    },
  },

  created() {
    this.getButtonPower();
    this.getManpowerSupplierMonthlyCostAccountings();

    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });

    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
    //岗位类别

    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });

    //岗位
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }

  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 33%;
        margin-bottom: 24px;
        .name {
          width: 120px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  // .el-table__body,
  // .el-table__footer,
  // .el-table__header {
  //   table-layout: auto;
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
  // .el-table__header {
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
